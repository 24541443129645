import request from '~/utils';

export const requestAuthGetUsers = async (page: number | string, id: string | null, user_id: string | number | null) => {
    try {
        const res = await request.get('/users', {
            params: {
                page,
                id,
                user_id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthSearchUser = async (keyword: string, type: string | null | undefined) => {
    try {
        const res = await request.get('/users/search', {
            params: { type, keyword },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthRegisterUser = async (data: object) => {
    try {
        const res = await request.post('/users/register', data);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthUpdateUser = async (id: string, data: object) => {
    try {
        const res = await request.put('/users/update', data, {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthDestroyUser = async (id: string) => {
    try {
        const res = await request.delete('/users/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
