import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Drawer, Flex, Form, Input, InputNumber, Row, Space, notification } from 'antd';

import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestAuthUpdateUserbank } from '~/services/bank';

interface LocalbankProp {
    id: number;
    _id: string;
    type: string;
    full_name: string;
    sub_name: string;
}

interface UserBankProp {
    id: number;
    _id: string;
    email: string;
    full_name: string;
}

interface UserbanksProps {
    id: number;
    key: string;
    branch: string;
    balance: number;
    status: boolean;
    updated_at: string;
    user: UserBankProp;
    created_at: string;
    card_number: string;
    description: string;
    phone_number: string;
    account_holder: string;
    account_number: string;
    localbank: LocalbankProp;
}

interface UserbankDetailProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: object[];
    setCallback: React.Dispatch<React.SetStateAction<any>>;
    userbank: UserbanksProps;
}

function UserbankDetail({ open, setOpen, userbank, callback, setCallback }: UserbankDetailProps) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpdateUserBank = async (data: any) => {
        const result = await requestAuthUpdateUserbank(userbank.key, 'info', data);

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutAuthSuccess());
            navigate(`/login`);
        } else if (result?.status === 200) {
            const cloneUserBanks = [...callback];

            const userBankIndex = cloneUserBanks.findIndex((item: any) => item.key === userbank.key);
            if (userBankIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy ngân hàng thành viên trong danh sách',
                });
            }

            cloneUserBanks[userBankIndex] = result.data;
            setCallback(cloneUserBanks);
            setOpen(false);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Drawer
            title="Chi tiết tài khoản ngân hàng"
            width={820}
            onClose={() => setOpen(false)}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleUpdateUserBank}
                initialValues={{
                    account_password: '',
                    branch: userbank.branch,
                    balance: userbank.balance,
                    user_id: userbank.user.email,
                    card_number: userbank.card_number,
                    phone_number: userbank.phone_number,
                    account_number: userbank.account_number,
                    account_holder: userbank.account_holder,
                    localbank_id: userbank.localbank.full_name,
                }}
            >
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="user_id"
                            label="Khách hàng"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Khách hàng" readOnly />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="localbank_id"
                            label="Ngân hàng"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Ngân hàng" readOnly />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="account_number"
                            label="Số tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập số tài khoản',
                                },
                            ]}
                        >
                            <Input placeholder="Số tài khoản" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="account_holder"
                            label="Chủ tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập chủ tài khoản',
                                },
                            ]}
                        >
                            <Input placeholder="Chủ tài khoản" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="account_password" label="Mật khẩu">
                            <Input.Password placeholder="Bỏ qua nếu không thay đổi" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="branch" label="Chi nhánh">
                            <Input placeholder="Chi nhánh" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="card_number" label="CCCD/CMND/Hộ chiếu">
                            <Input placeholder="CCCD/CMND/Hộ chiếu" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="phone_number" label="SĐT">
                            <Input placeholder="SĐT" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="balance" label="Số dư">
                            <InputNumber className="w-full" disabled={userbank.localbank.type === 'bank'} placeholder="Số dư" />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="end" style={{ marginTop: 22 }}>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Huỷ</Button>
                        <Button type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Drawer>
    );
}

export default UserbankDetail;
