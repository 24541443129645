import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fragment, useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconTrash, IconArrowLeft, IconInfoCircleFilled } from '@tabler/icons-react';
import {
    Card,
    Flex,
    Spin,
    Space,
    Table,
    Input,
    Image,
    Button,
    Switch,
    Tooltip,
    Popconfirm,
    Pagination,
    Breadcrumb,
    notification,
} from 'antd';

import useDebounce from '~/hooks/useDebounce';
import CreateLocalbank from './CreateLocalbank';
import LocalbankDetail from './LocalbankDetail';
import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import {
    requestAuthGetLocalbanks,
    requestAuthSearchLocalbank,
    requestAuthUpdateLocalbank,
    requestAuthDestroyLocalbank,
} from '~/services/bank';

interface LocalbanksProps {
    id: number;
    key: string;
    code: string;
    type: string;
    status: boolean;
    logo_url: string;
    sub_name: string;
    full_name: string;
    created_at: string;
    updated_at: string;
    description: string;
    interbank_code: string;
}

function Localbank() {
    const [pages, setPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState<string>('');
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [localbank, setLocalbank] = useState<LocalbanksProps>();
    const [localbanks, setLocalbanks] = useState<LocalbanksProps[]>([]);
    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1'));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const debounce = useDebounce(searchValue, 800);

    const id = searchParams.get('id');
    const localbank_id = searchParams.get('localbank_id');

    useEffect(() => {
        document.title = 'Ha2cm.com - Danh sách ngân hàng';

        const fetch = async () => {
            setLoading(true);
            const result = await requestAuthGetLocalbanks(page, id, localbank_id);

            if (result.status === 401 || result.status === 403) {
                navigate('/login');
                dispatch(logoutAuthSuccess());
            } else if (result?.status === 200) {
                setPages(result.pages);
                setLocalbanks(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
            setLoading(false);
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, id, localbank_id]);

    // Search
    useEffect(() => {
        if (debounce.length < 1) {
            return;
        }

        const fetch = async () => {
            const result = await requestAuthSearchLocalbank(debounce);

            if (result.status === 401 || result.status === 403) {
                dispatch(logoutAuthSuccess());
                navigate(`/login`);
            } else if (result?.status === 200) {
                setPages(1);
                setLocalbanks(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce]);

    const handleToggleStatusLocalbank = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID ngân hàng cần cập nhật',
            });
        }

        const result = await requestAuthUpdateLocalbank(id, 'status', {});

        if (result.status === 401 || result.status === 403) {
            dispatch(logoutAuthSuccess());
            navigate(`/login}`);
        } else if (result?.status === 200) {
            const cloneLocalbanks = [...localbanks];

            const localbankIndex = cloneLocalbanks.findIndex((localbank) => localbank.key === id);
            if (localbankIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy ID ngân hàng trong danh sách',
                });
            }

            cloneLocalbanks[localbankIndex].status = !cloneLocalbanks[localbankIndex].status;
            setLocalbanks(cloneLocalbanks);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const confirmDestroyLocalbank = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID tài khoản cần xoá',
            });
        }
        const result = await requestAuthDestroyLocalbank(id);

        if (result.status === 401 || result.status === 403) {
            navigate('/login');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const cloneLocalbanks = [...localbanks];

            const localbankIndex = cloneLocalbanks.findIndex((localbank) => localbank.key === id);
            if (localbankIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy tài khoản trong danh sách',
                });
            }

            cloneLocalbanks.splice(localbankIndex, 1);
            setLocalbanks(cloneLocalbanks);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => `#${id}`,
        },
        {
            title: 'Thông tin',
            key: 'info',
            render: (data: any) => (
                <Fragment>
                    <span>{data.sub_name}</span>
                    <br />
                    <span>{data.full_name}</span>
                </Fragment>
            ),
        },
        {
            title: 'Logo',
            dataIndex: 'logo_url',
            key: 'logo_url',
            render: (logo_url: string) => <Image width={40} height={40} src={logo_url} alt="Avatar" className="rounded-8" />,
        },
        {
            title: 'Loại',
            dataIndex: 'type',
            key: 'type',
            render: () => `Ngân hàng`,
        },
        {
            title: 'Mã',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Đầu thẻ',
            dataIndex: 'interbank_code',
            key: 'interbank_code',
        },
        {
            title: 'Trạng thái',
            key: 'status',
            render: (data: any) => (
                <Switch
                    checkedChildren="Bật"
                    unCheckedChildren="Tắt"
                    value={data.status}
                    onChange={() => handleToggleStatusLocalbank(data.key)}
                />
            ),
        },
        {
            title: 'Ngày tạo/Cập nhật',
            key: 'date',
            render: (data: any) => (
                <Fragment>
                    <span>{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                    <br />
                    <span>{moment(data.updated_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                </Fragment>
            ),
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data: any) => (
                <Space>
                    <Tooltip title="Xem chi tiết">
                        <Button
                            className="box-center"
                            type="primary"
                            size="small"
                            onClick={() => {
                                setOpenUpdate(true);
                                setLocalbank(data);
                            }}
                        >
                            <IconInfoCircleFilled size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xoá">
                        <Popconfirm
                            title="Delete?"
                            description={`#${data.id}`}
                            onConfirm={() => confirmDestroyLocalbank(data.key)}
                            okText="Xoá"
                            cancelText="Huỷ"
                            icon={<IconQuestion width={14} height={14} className="mt-1 mr-1" style={{ color: '#ff4d4f' }} />}
                        >
                            <Button danger type="primary" size="small" className="box-center">
                                <IconTrash size={18} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Space style={{ width: '100%', flexDirection: 'column' }}>
            <Card
                styles={{
                    body: {
                        padding: 12,
                    },
                }}
            >
                <Flex justify="space-between" align="center" className="responsive-flex">
                    <Flex className="gap-2 responsive-item" align="center">
                        <Button size="small" className="box-center" onClick={() => navigate('/')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/">Trang chủ</Link>,
                                },
                                {
                                    title: 'Danh sách ngân hàng',
                                },
                            ]}
                        />
                    </Flex>
                    <Flex justify="end" className="responsive-item">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Tìm kiếm"
                            value={searchValue}
                            style={{ width: 240 }}
                            className="mx-3"
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button style={{ display: 'flex', alignItems: 'center' }} type="primary" onClick={() => setOpenCreate(true)}>
                            <PlusOutlined />
                            Thêm mới
                        </Button>
                    </Flex>
                </Flex>
            </Card>

            {openCreate && <CreateLocalbank open={openCreate} setOpen={setOpenCreate} callback={localbanks} setCallback={setLocalbanks} />}
            {openUpdate && localbank && (
                <LocalbankDetail
                    open={openUpdate}
                    setOpen={setOpenUpdate}
                    localbank={localbank}
                    callback={localbanks}
                    setCallback={setLocalbanks}
                />
            )}

            <Card style={{ minHeight: 'calc(-171px + 100vh)' }}>
                {!loading ? (
                    <Table columns={columns} dataSource={localbanks} pagination={false} />
                ) : (
                    <Flex align="center" justify="center" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}

                {Number(pages) > 1 && (
                    <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                        <Pagination
                            current={page || 1}
                            pageSize={20}
                            total={Number(pages) * 20}
                            onChange={(page) => {
                                setPage(page);
                                setSearchParams({ page: page.toString() });
                            }}
                        />
                    </Flex>
                )}
            </Card>
        </Space>
    );
}

export default Localbank;
