import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fragment, useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconTrash, IconArrowLeft, IconInfoCircleFilled } from '@tabler/icons-react';
import { Card, Space, Table, Input, Button, Tooltip, Popconfirm, Pagination, notification, Breadcrumb, Flex, Spin, Switch } from 'antd';

import CreateWebhook from './CreateWebhook';
import UpdateWebhook from './UpdateWebhook';
import useDebounce from '~/hooks/useDebounce';
import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestDestroyWebhook, requestAuthGetWebhooks, requestUpdateWebhook, requestAuthSearchWebhook } from '~/services/webhook';

interface UserProp {
    id: number;
    _id: string;
    email: string;
    full_name: string;
}

interface LocalbankProp {
    id: number;
    _id: string;
    sub_name: string;
    full_name: string;
}

interface UserbankProp {
    id: number;
    _id: string;
    account_holder: string;
    account_number: string;
    localbank_id: LocalbankProp;
}

interface WebhookProps {
    id: number;
    key: string;
    title: string;
    user: UserProp;
    status: boolean;
    created_at: string;
    updated_at: string;
    secret_key: string;
    callback_url: string;
    userbank: UserbankProp;
    send_transaction: boolean;
}

function Webhook() {
    const [pages, setPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [webhook, setWebhook] = useState<WebhookProps>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState<string>('');
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [webhooks, setWebhooks] = useState<WebhookProps[]>([]);
    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1'));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const debounce = useDebounce(searchValue, 800);

    const id = searchParams.get('id');
    const webhook_id = searchParams.get('webhook_id');

    useEffect(() => {
        document.title = 'Ha2cm.com - Danh sách webhooks';

        const fetch = async () => {
            setLoading(true);
            const result = await requestAuthGetWebhooks(page, id, webhook_id);

            if (result.status === 401 || result.status === 403) {
                navigate('/login');
                dispatch(logoutAuthSuccess());
            } else if (result?.status === 200) {
                setPages(result.pages);
                setWebhooks(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
            setLoading(false);
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, id, webhook_id]);

    // Search
    useEffect(() => {
        if (debounce.length < 1) {
            return;
        }

        const fetch = async () => {
            const result = await requestAuthSearchWebhook(debounce);

            if (result.status === 401 || result.status === 403) {
                dispatch(logoutAuthSuccess());
                navigate(`/login`);
            } else if (result?.status === 200) {
                setPages(1);
                setWebhooks(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce]);

    const handleToggleStatusWebhook = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID webhook cần cập nhật',
            });
        }

        const result = await requestUpdateWebhook(id, 'status', {});

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const cloneWebhooks = [...webhooks];

            const webhookIndex = cloneWebhooks.findIndex((webhook) => webhook.key === id);
            if (webhookIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy webhook trong danh sách',
                });
            }

            cloneWebhooks[webhookIndex].status = !cloneWebhooks[webhookIndex].status;
            setWebhooks(cloneWebhooks);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const confirmDestroyWebhook = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID webhook cần xoá',
            });
        }
        const result = await requestDestroyWebhook(id);

        if (result.status === 401 || result.status === 403) {
            navigate('/login');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const cloneWebhooks = [...webhooks];

            const webhookIndex = cloneWebhooks.findIndex((webhook) => webhook.key === id);
            if (webhookIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy webhook trong danh sách',
                });
            }

            cloneWebhooks.splice(webhookIndex, 1);
            setWebhooks(cloneWebhooks);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => `#${id}`,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'user',
            key: 'user',
            render: (user: any) => (
                <Link to={`/users?id=${user._id}`} target="_blank">
                    <span>{user.full_name}</span>
                    <br />
                    <span>{user.email}</span>
                </Link>
            ),
        },
        {
            title: 'Tài khoản',
            dataIndex: 'userbank',
            key: 'userbank',
            render: (userbank: any) => (
                <Link to={`/userbanks?id=${userbank._id}`} target="_blank">
                    <span>{userbank.localbank_id.sub_name}</span>
                    <br />
                    <span>{userbank.account_number}</span>
                    <br />
                    <span>{userbank.account_holder}</span>
                </Link>
            ),
        },
        {
            title: 'Thông tin',
            key: 'info',
            render: (data: any) => (
                <Fragment>
                    <span>{data.title}</span>
                    <br />
                    <span>{data.callback_url}</span>
                </Fragment>
            ),
        },
        {
            title: 'Trạng thái',
            key: 'status',
            render: (data: any) => (
                <Switch
                    checkedChildren="Bật"
                    unCheckedChildren="Tắt"
                    value={data.status}
                    onChange={() => handleToggleStatusWebhook(data.key)}
                />
            ),
        },
        {
            title: 'Loại',
            dataIndex: 'send_transaction',
            key: 'send_transaction',
            render: (send_transaction: string) => {
                if (send_transaction === 'money_all') {
                    return 'Tất cả';
                }
                if (send_transaction === 'money_in') {
                    return 'Tiền vào';
                }
                if (send_transaction === 'money_out') {
                    return 'Tiền ra';
                }
            },
        },
        {
            title: 'Ngày tạo/Cập nhật',
            key: 'data',
            render: (data: any) => (
                <Fragment>
                    <span>{moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                    <br />
                    <span>{moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}</span>
                </Fragment>
            ),
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data: any) => (
                <Space>
                    <Tooltip title="Xem chi tiết">
                        <Button
                            className="box-center"
                            type="primary"
                            size="small"
                            onClick={() => {
                                setWebhook(data);
                                setOpenUpdate(true);
                            }}
                        >
                            <IconInfoCircleFilled size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xoá">
                        <Popconfirm
                            title="Delete?"
                            description={`#${data.id}`}
                            onConfirm={() => confirmDestroyWebhook(data.key)}
                            okText="Xoá"
                            cancelText="Huỷ"
                            icon={<IconQuestion width={14} height={14} className="mt-1 mr-1" style={{ color: '#ff4d4f' }} />}
                        >
                            <Button danger type="primary" size="small" className="box-center">
                                <IconTrash size={18} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Space style={{ width: '100%', flexDirection: 'column' }}>
            <Card
                styles={{
                    body: {
                        padding: 12,
                    },
                }}
            >
                <Flex justify="space-between" align="center" className="responsive-flex">
                    <Flex className="gap-2 responsive-item" align="center">
                        <Button size="small" className="box-center" onClick={() => navigate('/')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/">Trang chủ</Link>,
                                },

                                {
                                    title: 'Danh sách webhooks',
                                },
                            ]}
                        />
                    </Flex>
                    <Flex justify="end" className="responsive-item">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Tìm kiếm"
                            value={searchValue}
                            style={{ width: 240 }}
                            className="mx-3"
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button style={{ display: 'flex', alignItems: 'center' }} type="primary" onClick={() => setOpenCreate(true)}>
                            <PlusOutlined />
                            Thêm mới
                        </Button>
                    </Flex>
                </Flex>
            </Card>

            {openCreate && <CreateWebhook open={openCreate} setOpen={setOpenCreate} callback={webhooks} setCallback={setWebhooks} />}
            {openUpdate && webhook && (
                <UpdateWebhook open={openUpdate} setOpen={setOpenUpdate} webhook={webhook} callback={webhooks} setCallback={setWebhooks} />
            )}

            <Card style={{ minHeight: 'calc(-171px + 100vh)' }}>
                {!loading ? (
                    <Table columns={columns} dataSource={webhooks} pagination={false} />
                ) : (
                    <Flex align="center" justify="center" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}

                {Number(pages) > 1 && (
                    <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                        <Pagination
                            current={page || 1}
                            pageSize={20}
                            total={Number(pages) * 20}
                            onChange={(page) => {
                                setPage(page);
                                setSearchParams({ page: page.toString() });
                            }}
                        />
                    </Flex>
                )}
            </Card>
        </Space>
    );
}

export default Webhook;
