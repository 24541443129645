import React from 'react';
import Home from '~/layouts/Home';
import NotFound from '~/layouts/NotFound';
import Users from '~/pages/accountPage/Users';
import Login from '~/layouts/components/Login';
import Userbank from '~/pages/bankPage/Userbank';
import Webhook from '~/pages/webhookPage/Webhook';
import Localbank from '~/pages/bankPage/Localbank';
import Callbacks from '~/pages/statisticPage/Callbacks';
import Transactions from '~/pages/statisticPage/Transactions';
import ExportDatabase from '~/pages/databasePage/ExportDatabase';

interface CustomRouteProps {
    path: string;
    layout?: boolean;
    component: React.ComponentType<any>;
}

export const privateRoutes: CustomRouteProps[] = [
    { path: '/', component: Home, layout: true },
    { path: '*', component: NotFound, layout: false },
    { path: '/users', component: Users, layout: true },
    { path: '/login', component: Login, layout: false },
    { path: '/webhooks', component: Webhook, layout: true },
    { path: '/userbanks', component: Userbank, layout: true },
    { path: '/callbacks', component: Callbacks, layout: true },
    { path: '/localbanks', component: Localbank, layout: true },
    { path: '/transactions', component: Transactions, layout: true },
    { path: '/databases/exports', component: ExportDatabase, layout: true },
];
