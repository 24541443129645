import request from '~/utils';

export const requestAuthGetWebhooks = async (page: number | string, id: string | null, webhook_id: string | number | null) => {
    try {
        const res = await request.get('/webhooks', {
            params: {
                page,
                id,
                webhook_id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthSearchUserbank = async (type: string, keyword: string) => {
    try {
        const res = await request.get('/userbanks/search', {
            params: {
                type,
                keyword,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthSearchWebhook = async (keyword: string) => {
    try {
        const res = await request.get('/webhooks/search', {
            params: { keyword },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthCreateWebhook = async (data: object) => {
    try {
        const res = await request.post('/webhooks/create', data);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestUpdateWebhook = async (id: string, type: string, data: object) => {
    try {
        const res = await request.put('/webhooks/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestDestroyWebhook = async (id: string) => {
    try {
        const res = await request.delete('/webhooks/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
