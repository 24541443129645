import { Modal, Button, message } from 'antd';
import { IconCopy } from '@tabler/icons-react';

interface ModelTransactionProps {
    open: boolean;
    title: string;
    callback: object;
    onCancel: React.Dispatch<React.SetStateAction<boolean>>;
}

function ModelTransaction({ open, onCancel, callback, title }: ModelTransactionProps) {
    const handleClickCopy = (text: any) => {
        navigator.clipboard
            .writeText(JSON.stringify(text))
            .then(() => {
                message.success('Đã sao chép vào keyboard');
            })
            .catch((err) => {
                message.error(`Lỗi sao chép: ${err}`);
            });
    };

    return (
        <Modal
            title={<h2 className="font-size-16 p-3 border-bottom">{title}</h2>}
            open={open}
            onOk={() => onCancel(false)}
            onCancel={() => onCancel(false)}
            width={1200}
            okText="Chuẩn"
            cancelText="Hủy"
            styles={{
                content: {
                    padding: 0,
                },
                body: {
                    padding: 16,
                },
                footer: {
                    padding: '0 16px 16px',
                },
            }}
        >
            <div className="api-detail">
                <div className="copy">
                    <pre>
                        <code className="text-copy">{JSON.stringify(callback, null, 3)}</code>
                    </pre>

                    <div className="btn-copy">
                        <Button size="small" className="box-center" onClick={() => handleClickCopy(callback)}>
                            <IconCopy size={18} />
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ModelTransaction;
