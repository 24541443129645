import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Drawer, Flex, Form, Input, Row, Select, Space, Tooltip, notification } from 'antd';

import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestUpdateWebhook } from '~/services/webhook';

interface UserProp {
    id: number;
    _id: string;
    email: string;
    full_name: string;
}

interface LocalbankProp {
    id: number;
    _id: string;
    sub_name: string;
    full_name: string;
}

interface UserbankProp {
    id: number;
    _id: string;
    account_holder: string;
    account_number: string;
    localbank_id: LocalbankProp;
}

interface WebhookProps {
    id: number;
    key: string;
    title: string;
    user: UserProp;
    status: boolean;
    created_at: string;
    updated_at: string;
    secret_key: string;
    callback_url: string;
    userbank: UserbankProp;
    send_transaction: boolean;
}

interface UpdateWebhookProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: object[];
    setCallback: React.Dispatch<React.SetStateAction<any>>;
    webhook: WebhookProps;
}

function UpdateWebhook({ open, setOpen, webhook, callback, setCallback }: UpdateWebhookProps) {
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpdateWebhook = async (values: WebhookProps) => {
        const { title, send_transaction, callback_url, secret_key } = values;

        const data = {
            title,
            send_transaction,
            callback_url,
            secret_key,
        };
        const result = await requestUpdateWebhook(webhook.key, 'info', data);

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const cloneWebhooks = [...callback];
            const webhookIndex = cloneWebhooks.findIndex((hook: any) => hook.key === webhook.key);

            if (webhookIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy webhook trong danh sách',
                });
            }

            cloneWebhooks[webhookIndex] = result.data;
            setCallback(cloneWebhooks);
            setOpen(false);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Drawer
            title="Chi tiết webhook"
            width={820}
            onClose={() => setOpen(false)}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleUpdateWebhook}
                initialValues={{
                    title: webhook.title,
                    user_id: webhook.user.email,
                    secret_key: webhook.secret_key,
                    callback_url: webhook.callback_url,
                    send_transaction: webhook.send_transaction,
                    userbank_id: `${webhook.userbank.account_number}/${webhook.userbank.localbank_id.sub_name}`,
                }}
            >
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="user_id"
                            label="Khách hàng"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Khách hàng" readOnly />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="userbank_id"
                            label="Tài khoản"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input placeholder="Tài khoản" readOnly />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="title"
                            label="Tên hiển thị"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập tên hiển thị',
                                },
                            ]}
                        >
                            <Input placeholder="Tên hiển thị" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="send_transaction"
                            label="Loại giao dịch"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn loại giao dịch',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn loại giao dịch"
                                options={[
                                    { label: 'Tất cả', value: 'money_all' },
                                    { label: 'Tiền vào', value: 'money_in' },
                                    { label: 'Tiền ra', value: 'money_out' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="callback_url"
                            label="Callback URL"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập callback URL',
                                },
                            ]}
                        >
                            <Input placeholder="Callback URL" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="secret_key"
                            label={
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <span>Khoá bí mật</span>
                                    <Tooltip title="Khoá này sẽ được đính kèm theo headers mỗi request callback">
                                        <span style={{ cursor: 'pointer' }}>
                                            <IconQuestion width={12} height={12} />
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        >
                            <Input placeholder="Khoá bí mật" />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="end" style={{ marginTop: 22 }}>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Huỷ</Button>
                        <Button type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Drawer>
    );
}

export default UpdateWebhook;
