import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Drawer, Flex, Form, Input, Row, Select, Space, Tooltip, notification } from 'antd';

import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestAuthSearchUser } from '~/services/account';
import { requestAuthCreateWebhook } from '~/services/webhook';
import { requestAuthSearchUserbank } from '~/services/webhook';

let timeout: any;

const fetchSearchServices = (value: string, callback: (result: any) => void, type: string) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    const fake = async () => {
        const result = await requestAuthSearchUser(value, type);

        if (result.status === 200) {
            const data = result.data.map((user: any) => {
                return {
                    value: user.id,
                    text: user.title,
                };
            });
            callback(data);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    if (value.length > 1) {
        timeout = setTimeout(fake, 600);
    } else {
        callback([]);
    }
};

interface SearchInputProps {
    value?: string;
    onChange?: (value: string) => void;
    placeholder: string;
    type: string;
    style?: React.CSSProperties;
    onUserSelect: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
    const [data, setData] = useState([]);
    const [value, setValue] = useState<string>('');

    const handleSearch = (newValue: string) => {
        fetchSearchServices(newValue, setData, props.type);
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);

        if (props.onChange) {
            props.onChange(newValue);
        }

        // Gọi hàm callback khi chọn người dùng
        if (props.onUserSelect) {
            props.onUserSelect(newValue);
        }
    };

    return (
        <Select
            showSearch
            value={value}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            options={(data || []).map((d: any) => ({
                value: d.value,
                label: d.text,
            }))}
        />
    );
};

interface CreateWebhookProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: object[];
    setCallback: React.Dispatch<React.SetStateAction<any>>;
}

function CreateWebhook({ open, setOpen, callback, setCallback }: CreateWebhookProps) {
    const [optionUserbanks, setOptionUserbanks] = useState<[]>([]);
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSearchUserbank = async (userId: string) => {
        const result = await requestAuthSearchUserbank('webhook', userId);

        if (result.status === 200) {
            setOptionUserbanks(result.data);
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const handleCreateWebhook = async (data: object) => {
        const result = await requestAuthCreateWebhook(data);

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            setCallback([result.data, ...callback]);

            form.resetFields();
            setOpen(false);
            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Drawer
            title="Thêm mới webhook"
            width={820}
            onClose={() => setOpen(false)}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            <Form layout="vertical" form={form} onFinish={handleCreateWebhook} initialValues={{ secret_key: '' }}>
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="user_id"
                            label="Khách hàng"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn khách hàng',
                                },
                            ]}
                        >
                            <SearchInput
                                placeholder="Chọn khách hàng"
                                type="user_id"
                                onUserSelect={(userId) => {
                                    handleSearchUserbank(userId);
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col md={12} xs={24}>
                        <Form.Item
                            name="userbank_id"
                            label="Chọn tài khoản"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn tài khoản',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn tài khoản"
                                options={optionUserbanks.map((userbank: any) => {
                                    return {
                                        value: userbank.id,
                                        label: userbank.title,
                                    };
                                })}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="title"
                            label="Tên hiển thị"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập tên hiển thị',
                                },
                            ]}
                        >
                            <Input placeholder="Tên hiển thị" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="send_transaction"
                            label="Loại giao dịch"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn loại giao dịch',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn loại giao dịch"
                                options={[
                                    { label: 'Tất cả', value: 'money_all' },
                                    { label: 'Tiền vào', value: 'money_in' },
                                    { label: 'Tiền ra', value: 'money_out' },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="callback_url"
                            label="Callback URL"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập callback URL',
                                },
                            ]}
                        >
                            <Input placeholder="Callback URL" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="secret_key"
                            label={
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <span>Khoá bí mật</span>
                                    <Tooltip title="Khoá này sẽ được đính kèm theo headers mỗi request callback">
                                        <span style={{ cursor: 'pointer' }}>
                                            <IconQuestion width={12} height={12} />
                                        </span>
                                    </Tooltip>
                                </div>
                            }
                        >
                            <Input placeholder="Khoá bí mật" />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="end" style={{ marginTop: 22 }}>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Huỷ</Button>
                        <Button type="primary" htmlType="submit">
                            Tạo đối tác
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Drawer>
    );
}

export default CreateWebhook;
