import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Card, Col, Flex, notification, Row, Spin, Tag } from 'antd';

import Callbacks from './Callbacks';
import Transactions from './Transactions';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestAuthGetStatistics } from '~/services/statistic';
import imageAvatarDefault from '~/assets/image/avatar-default.png';

interface HomeProps {
    items: object[];
    today_count: number;
    total_count: number;
}

function Home() {
    const [loading, setLoading] = useState<boolean>(false);
    const [callbacks, setCallbacks] = useState<HomeProps>({ items: [], today_count: 0, total_count: 0 });
    const [transactions, setTransactions] = useState<HomeProps>({ items: [], today_count: 0, total_count: 0 });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { currentUser } = useSelector((state: any) => state.auth);

    useEffect(() => {
        document.title = 'Ha2cm.com - Trang chủ';

        const fetch = async () => {
            setLoading(true);
            const result = await requestAuthGetStatistics();

            setLoading(false);
            if (result.status === 401 || result.status === 403) {
                navigate('/login');
                dispatch(logoutAuthSuccess());
            } else if (result.status === 200) {
                setCallbacks(result.data.callbacks);
                setTransactions(result.data.transactions);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row style={{ margin: '0 -8px', rowGap: 16 }}>
            <Col md={18} className="home-col" style={{ padding: '0 8px' }}>
                {loading ? (
                    <Flex align="center" justify="center" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                ) : (
                    <Card className="rounded-15 cart-container" styles={{ body: { padding: 18 } }}>
                        <div>
                            <Flex align="center" className="font-semibold font-size-20 mb-5">
                                <span className="mr-3">Lịch sử giao dịch</span>
                                <Tag color="#28a745">Mới {transactions.today_count}</Tag>
                                <Tag color="#17a2b8">Tổng {transactions.total_count}</Tag>
                            </Flex>

                            <Transactions histories={transactions.items} />
                        </div>

                        <div style={{ marginTop: 50 }}>
                            <Flex align="center" className="font-semibold font-size-20 mb-5">
                                <span className="mr-3">Lịch sử callback</span>
                                <Tag color="#28a745">Mới {callbacks.today_count}</Tag>
                                <Tag color="#17a2b8">Tổng {callbacks.total_count}</Tag>
                            </Flex>

                            <Callbacks histories={callbacks.items} />
                        </div>
                    </Card>
                )}
            </Col>

            <Col md={6} style={{ padding: '0 8px' }} className="flex-1">
                <Card
                    className="rounded-15 mb-4"
                    title={
                        <h2 className="font-semibold mb-0 white-space-break">
                            <span className="font-size-18 font-semibold">Thông tin tài khoản</span>
                        </h2>
                    }
                >
                    <Flex align="center" justify="center" className="h-full flex-column">
                        <Avatar
                            src={currentUser?.avatar_url || imageAvatarDefault}
                            style={{ fontSize: 50, width: 100, height: 100, lineHeight: 100, background: '#00000040' }}
                        />
                        <h3 className="text-center font-semibold mt-2 mb-0 font-size-20">{currentUser?.full_name}</h3>
                        <h3 className="text-center mt-1 mb-0 text-subtitle font-size-16">{currentUser?.email}</h3>

                        <div className="w-full mt-6">
                            <Button block className="rounded-10 mt-4 min-height-35">
                                Quản lý tài khoản
                            </Button>
                            <Button block type="primary" className="rounded-10 mt-2 min-height-35 boxshadow-none">
                                Nạp tiền vào tài khoản
                            </Button>
                        </div>
                    </Flex>
                </Card>
            </Col>
        </Row>
    );
}

export default Home;
