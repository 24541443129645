export const convertCurrency = (number: number | null | undefined | string) => {
    if (number == null || number === undefined) {
        return 'Null';
    }

    const amount = Number(number);
    let check = typeof amount === 'number' ? true : false;

    return check ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'đ' : 'Null';
};

export function checkImage(urlImage: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = function () {
            resolve(urlImage); // Trả về chuỗi URL nếu ảnh tải thành công
        };

        img.onerror = function () {
            resolve(null); // Trả về null nếu ảnh tải thất bại
        };

        img.src = urlImage;
    });
}

// Hàm lấy base64
export const configGetBase64 = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
