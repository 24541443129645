import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fragment, useState, useEffect } from 'react';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IconTrash, IconArrowLeft, IconInfoCircleFilled } from '@tabler/icons-react';
import { Card, Space, Table, Input, Button, Tooltip, Popconfirm, Pagination, notification, Breadcrumb, Flex, Spin, Tag } from 'antd';

import UpdateUser from './UpdateUser';
import RegisterUser from './RegisterUser';
import useDebounce from '~/hooks/useDebounce';
import IconQuestion from '~/assets/icon/IconQuestion';
import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestAuthDestroyUser, requestAuthGetUsers, requestAuthSearchUser } from '~/services/account';

interface UsersProps {
    id: number;
    key: string;
    email: string;
    role: [string];
    gender: string;
    status: string;
    username: string;
    last_name: string;
    full_name: string;
    first_name: string;
    avatar_url: string;
    created_at: string;
    updated_at: string;
    phone_number: string;
    register_type: string;
    birthday: string | null;
    email_verified: boolean;
    phone_verified: boolean;
    last_login_at: string | null;
}

function Users() {
    const [pages, setPages] = useState<number>(1);
    const [user, setUser] = useState<UsersProps>();
    const [users, setUsers] = useState<UsersProps[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState<string>('');
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openUpdate, setOpenUpdate] = useState<boolean>(false);
    const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1'));

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const debounce = useDebounce(searchValue, 800);

    const id = searchParams.get('id');
    const user_id = searchParams.get('user_id');

    useEffect(() => {
        document.title = 'Ha2cm.com - Danh sách tài khoản';

        const fetch = async () => {
            setLoading(true);
            const result = await requestAuthGetUsers(page, id, user_id);

            if (result.status === 401 || result.status === 403) {
                navigate('/login');
                dispatch(logoutAuthSuccess());
            } else if (result?.status === 200) {
                setPages(result.pages);
                setUsers(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
            setLoading(false);
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, id, user_id]);

    // Search
    useEffect(() => {
        if (debounce.length < 1) {
            return;
        }

        const fetch = async () => {
            const result = await requestAuthSearchUser(debounce, null);

            if (result.status === 401 || result.status === 403) {
                dispatch(logoutAuthSuccess());
                navigate(`/login`);
            } else if (result?.status === 200) {
                setPages(1);
                setUsers(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce]);

    const confirmDestroyUser = async (id: string) => {
        if (!id) {
            return notification.error({
                message: 'Thông báo',
                description: 'Không lấy được ID tài khoản cần xoá',
            });
        }
        const result = await requestAuthDestroyUser(id);

        if (result.status === 401 || result.status === 403) {
            navigate('/login');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const cloneUsers = [...users];

            const userIndex = cloneUsers.findIndex((user) => user.key === id);
            if (userIndex === -1) {
                return notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy tài khoản trong danh sách',
                });
            }

            cloneUsers.splice(userIndex, 1);
            setUsers(cloneUsers);

            notification.success({
                message: 'Thông báo',
                description: result.message,
            });
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => `#${id}`,
        },
        {
            title: 'Họ và tên',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Thông tin',
            key: 'info',
            render: (data: any) => (
                <Fragment>
                    <span>{data.username}</span>
                    <br />
                    <span>{data.email}</span>
                    <br />
                    <span>{data.phone_number}</span>
                </Fragment>
            ),
        },
        {
            title: 'Đăng ký',
            dataIndex: 'register_type',
            key: 'register_type',
            render: (register_type: string) => {
                let color = '#40bc86';
                if (register_type === 'google') {
                    color = '#1890ff';
                }
                if (register_type === 'facebook') {
                    color = '#4267b2';
                }
                if (register_type === 'github') {
                    color = '#a5a8ab';
                }

                return <Tag color={color}>{register_type.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'Vai trò',
            dataIndex: 'role',
            key: 'role',
            render: (role: [string]) => (
                <Fragment>
                    {role.map((tag, index) => {
                        let color = '#52c41a';
                        if (tag === 'delete') {
                            color = '#ff4d4f';
                        }
                        if (tag === 'create') {
                            color = '#1890ff';
                        }
                        if (tag === 'edit') {
                            color = '#13c2c2';
                        }
                        if (tag === 'view') {
                            color = '#2db7f5';
                        }
                        if (tag === 'admin') {
                            color = '#722ed1';
                        }
                        const classTag = index > 2 ? 'mt-2' : '';
                        return (
                            <Fragment key={index}>
                                {index % 3 === 0 && index !== 0 && <br />}
                                <Tag color={color} className={classTag}>
                                    {tag.toUpperCase()}
                                </Tag>
                            </Fragment>
                        );
                    })}
                </Fragment>
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                let color = '#52c41a';
                let message = 'Hoạt động';
                if (status === 'inactivated') {
                    color = '#ffa940';
                    message = 'Tạm khoá';
                }
                if (status === 'blocked') {
                    color = '#ff4d4f';
                    message = 'Bị khóa';
                }
                return <Tag color={color}>{message.toUpperCase()}</Tag>;
            },
        },
        {
            title: 'Ngày tạo/Đăng nhập',
            key: 'date',
            render: (data: any) => (
                <Fragment>
                    <Tooltip title="Thời gian đăng ký tài khoản">
                        <span>{moment(data.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                    </Tooltip>
                    <br />
                    <Tooltip title="Thời gian đăng nhập cuối cùng">
                        {data.last_login_at ? <span>{moment(data.last_login_at).format('DD/MM/YYYY HH:mm:ss')}</span> : 'Chưa đăng nhập'}
                    </Tooltip>
                </Fragment>
            ),
        },
        {
            title: 'Hành động',
            key: 'action',
            render: (data: any) => (
                <Space>
                    <Tooltip title="Xem chi tiết">
                        <Button
                            className="box-center"
                            type="primary"
                            size="small"
                            onClick={() => {
                                setOpenUpdate(true);
                                setUser(data);
                            }}
                        >
                            <IconInfoCircleFilled size={18} />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Xoá">
                        <Popconfirm
                            title="Delete?"
                            description={data.full_name}
                            onConfirm={() => confirmDestroyUser(data.key)}
                            okText="Xoá"
                            cancelText="Huỷ"
                            icon={<IconQuestion width={14} height={14} className="mt-1 mr-1" style={{ color: '#ff4d4f' }} />}
                        >
                            <Button danger type="primary" size="small" className="box-center">
                                <IconTrash size={18} />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <Space style={{ width: '100%', flexDirection: 'column' }}>
            <Card
                styles={{
                    body: {
                        padding: 12,
                    },
                }}
            >
                <Flex justify="space-between" align="center" className="responsive-flex">
                    <Flex className="gap-2 responsive-item" align="center">
                        <Button size="small" className="box-center" onClick={() => navigate('/')}>
                            <IconArrowLeft size={18} />
                        </Button>
                        <Breadcrumb
                            items={[
                                {
                                    title: <Link to="/">Trang chủ</Link>,
                                },
                                {
                                    title: 'Danh sách người dùng',
                                },
                            ]}
                        />
                    </Flex>
                    <Flex justify="end" className="responsive-item">
                        <Input
                            prefix={<SearchOutlined />}
                            placeholder="Tìm kiếm"
                            value={searchValue}
                            style={{ width: 240 }}
                            className="mx-3"
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Button style={{ display: 'flex', alignItems: 'center' }} type="primary" onClick={() => setOpenCreate(true)}>
                            <PlusOutlined />
                            Thêm mới
                        </Button>
                    </Flex>
                </Flex>
            </Card>

            {openCreate && <RegisterUser open={openCreate} setOpen={setOpenCreate} callback={users} setCallback={setUsers} />}
            {openUpdate && user && (
                <UpdateUser open={openUpdate} setOpen={setOpenUpdate} user={user} callback={users} setCallback={setUsers} />
            )}

            <Card style={{ minHeight: 'calc(-171px + 100vh)' }}>
                {!loading ? (
                    <Table columns={columns} dataSource={users} pagination={false} />
                ) : (
                    <Flex align="center" justify="center" style={{ minHeight: '60vh' }}>
                        <Spin />
                    </Flex>
                )}

                {Number(pages) > 1 && (
                    <Flex justify="end" style={{ margin: '20px 0 10px 0' }}>
                        <Pagination
                            current={page || 1}
                            pageSize={20}
                            total={Number(pages) * 20}
                            onChange={(page) => {
                                setPage(page);
                                setSearchParams({ page: page.toString() });
                            }}
                        />
                    </Flex>
                )}
            </Card>
        </Space>
    );
}

export default Users;
