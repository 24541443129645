import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Drawer, Flex, Form, Input, Row, Select, Space, notification } from 'antd';

import { logoutAuthSuccess } from '~/redux/reducer/auth';
import { requestAuthUpdateUser } from '~/services/account';

interface UserProps {
    id: number;
    key: string;
    email: string;
    role: [string];
    gender: string;
    status: string;
    username: string;
    last_name: string;
    full_name: string;
    first_name: string;
    avatar_url: string;
    created_at: string;
    updated_at: string;
    phone_number: string;
    register_type: string;
    birthday: string | null;
    email_verified: boolean;
    phone_verified: boolean;
    last_login_at: string | null;
}

interface RoleProps {
    label: string;
    value: string;
}

interface UpdateUserProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    callback: object[];
    setCallback: React.Dispatch<React.SetStateAction<any>>;
    user: UserProps;
}

const OPTIONS = [
    {
        label: 'USER',
        value: 'user',
    },
    {
        label: 'CREATE',
        value: 'create',
    },
    {
        label: 'EDIT',
        value: 'edit',
    },
    {
        label: 'DELETE',
        value: 'delete',
    },
    {
        label: 'VIEW',
        value: 'view',
    },
];

function UpdateUser({ open, setOpen, callback, setCallback, user }: UpdateUserProps) {
    const [role, setRole] = useState<RoleProps[]>([]);
    const filteredOptions = OPTIONS.filter((o) => !role.includes(o));
    const [form] = Form.useForm();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleUpdateUser = async (data: object) => {
        const result = await requestAuthUpdateUser(user.key, data);

        if (result.status === 401 || result.status === 403) {
            navigate('/');
            dispatch(logoutAuthSuccess());
        } else if (result?.status === 200) {
            const cloneUsers = [...callback];
            const userIndex = cloneUsers.findIndex((u: any) => u.key === user.key);

            if (userIndex !== -1) {
                cloneUsers[userIndex] = result.data;
                setCallback(cloneUsers);
                setOpen(false);
                notification.success({
                    message: 'Thông báo',
                    description: result.message,
                });
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không tìm thấy đối tác trong danh sách',
                });
            }
        } else {
            notification.error({
                message: 'Thông báo',
                description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
            });
        }
    };

    return (
        <Drawer
            title="Chi tiết tài khoản"
            width={720}
            onClose={() => setOpen(false)}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleUpdateUser}
                initialValues={{
                    password: '',
                    role: user.role,
                    email: user.email,
                    status: user.status,
                    username: user.username,
                    last_name: user.last_name,
                    first_name: user.first_name,
                    phone_number: user.phone_number,
                    email_verified: user.email_verified,
                    phone_verified: user.phone_verified,
                }}
            >
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="first_name"
                            label="Họ"
                            rules={[
                                {
                                    required: true,
                                    min: 2,
                                    message: 'Vui lòng nhập họ người dùng',
                                },
                            ]}
                        >
                            <Input placeholder="Nhập họ người dùng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="last_name"
                            label="Tên"
                            rules={[
                                {
                                    required: true,
                                    min: 2,
                                    message: 'Vui lòng nhập tên người dùng',
                                },
                            ]}
                        >
                            <Input placeholder="Nhập tên người dùng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập username người dùng',
                                },
                            ]}
                        >
                            <Input placeholder="Nhập username người dùng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item name="password" label="Mật khẩu">
                            <Input.Password placeholder="Bỏ qua nếu không thay đổi" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'Vui lòng nhập email người dùng',
                                },
                            ]}
                        >
                            <Input placeholder="Nhập email người dùng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="email_verified"
                            label="Trạng thái email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn trạng thái',
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: 'Đã xác minh',
                                        value: true,
                                    },
                                    {
                                        label: 'Chưa xác minh',
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="phone_number"
                            label="SĐT"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập số điện thoại',
                                },
                            ]}
                        >
                            <Input placeholder="Nhập sđt người dùng" />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="phone_verified"
                            label="Trạng thái SĐT"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn trạng thái',
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        label: 'Đã xác minh',
                                        value: true,
                                    },
                                    {
                                        label: 'Chưa xác minh',
                                        value: false,
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="status"
                            label="Trạng thái"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn trạng thái người dùng',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Chọn trạng thái người dùng"
                                options={[
                                    {
                                        value: 'activated',
                                        label: 'Hoạt động',
                                    },
                                    {
                                        value: 'inactivated',
                                        label: 'Không hoạt động',
                                    },
                                    {
                                        value: 'blocked',
                                        label: 'Bị chặn',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item
                            name="role"
                            label="Vai trò"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng chọn vai trò người dùng',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                placeholder="Chọn vai trò người dùng"
                                onChange={setRole}
                                options={filteredOptions.map((item) => ({
                                    value: item.value,
                                    label: item.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Flex justify="end" style={{ marginTop: 22 }}>
                    <Space>
                        <Button onClick={() => setOpen(false)}>Huỷ</Button>
                        <Button type="primary" htmlType="submit">
                            Cập nhật
                        </Button>
                    </Space>
                </Flex>
            </Form>
        </Drawer>
    );
}

export default UpdateUser;
