import request from '~/utils';

export const requestAuthGetLocalbanks = async (page: number | string, id: string | null, localbank_id: string | number | null) => {
    try {
        const res = await request.get('/localbanks', {
            params: {
                page,
                id,
                localbank_id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthSearchLocalbank = async (keyword: string, type = null) => {
    try {
        const res = await request.get('/localbanks/search', {
            params: { type, keyword },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthCreateLocalbank = async (data: object) => {
    try {
        const res = await request.post('/localbanks/create', data);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthUpdateLocalbank = async (id: string, type: string, data: object) => {
    try {
        const res = await request.put('/localbanks/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthDestroyLocalbank = async (id: string) => {
    try {
        const res = await request.delete('/localbanks/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthGetUserbanks = async (page: number | string, id: string | null, userbank_id: string | number | null) => {
    try {
        const res = await request.get('/userbanks', {
            params: {
                page,
                id,
                userbank_id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthSearchUserbank = async (keyword: string, type = null) => {
    try {
        const res = await request.get('/userbanks/search', {
            params: { type, keyword },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthGetSearchUserbank = async (type: string, keyword: string) => {
    try {
        let res = { data: null };

        if (type === 'user_id') {
            res = await request.get('/users/search', {
                params: {
                    type,
                    keyword,
                },
            });
        }

        if (type === 'localbank_id') {
            res = await request.get('/localbanks/search', {
                params: {
                    type,
                    keyword,
                },
            });
        }

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthCreateUserbank = async (data: object) => {
    try {
        const res = await request.post('/userbanks/create', data);

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthUpdateUserbank = async (id: string, type: string, data: object) => {
    try {
        const res = await request.put('/userbanks/update', data, {
            params: {
                type,
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthDestroyUserbank = async (id: string) => {
    try {
        const res = await request.delete('/userbanks/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
