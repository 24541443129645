import request from '~/utils';

interface TransactionQueryProps {
    status?: string;
    currency?: string;
    date_end?: string;
    date_start?: string;
    id?: number | string;
    page: number | string;
    transaction_id?: number | string;
}

export const requestAuthGetTransactions = async ({
    status,
    currency,
    date_start,
    date_end,
    id,
    page,
    transaction_id,
}: TransactionQueryProps) => {
    try {
        const res = await request.get('/transactions', {
            params: {
                page,
                status,
                currency,
                date_start,
                date_end,
                id,
                transaction_id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestDestroyTransaction = async (id: string) => {
    try {
        const res = await request.delete('/transactions/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestCallbackTransaction = async (id: string) => {
    try {
        const res = await request.get('/transactions/callback', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

interface CallbackQueryProps {
    status?: string;
    date_end?: string;
    date_start?: string;
    id?: number | string;
    page: number | string;
    callback_id?: number | string;
    transaction_id?: number | string;
}

export const requestAuthGetCallbacks = async ({
    status,
    date_start,
    date_end,
    id,
    transaction_id,
    callback_id,
    page,
}: CallbackQueryProps) => {
    try {
        const res = await request.get('/callbacks', {
            params: {
                page,
                status,
                date_start,
                date_end,
                id,
                transaction_id,
                callback_id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthDestroyCallback = async (id: string) => {
    try {
        const res = await request.delete('/callbacks/destroy', {
            params: {
                id,
            },
        });

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};

export const requestAuthGetStatistics = async () => {
    try {
        const res = await request.get('/statistics');

        return res.data;
    } catch (error: any) {
        return error.response?.data;
    }
};
