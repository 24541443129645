import moment from 'moment';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';

import { convertCurrency } from '~/configs';

interface CallbackProps {
    histories: object[];
}

function Callbacks({ histories }: CallbackProps) {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (id: number) => (
                <Link to={`/callbacks?callback_id=${id}`} target="_blank">
                    #{id}
                </Link>
            ),
        },
        {
            title: 'ID giao dịch',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
            render: (transaction_id: number) => (
                <Link to={`/transactions?transaction_id=${transaction_id}`} target="_blank">
                    #{transaction_id}
                </Link>
            ),
        },
        {
            title: 'Webhook',
            dataIndex: 'webhook_id',
            key: 'webhook_id',
            render: (webhook_id: any) => (
                <Link to={`/webhooks?id=${webhook_id._id}`} target="_blank">
                    {webhook_id.title}
                </Link>
            ),
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => {
                let className = '';
                let title = '';
                if (amount >= 0) {
                    title = `+${convertCurrency(amount)}`;
                    className = 'text-success';
                } else {
                    title = convertCurrency(amount);
                    className = 'text-danger';
                }

                return <span className={className}>{title}</span>;
            },
        },
        {
            title: 'Số dư cuối',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance: number) => <Fragment>{convertCurrency(balance)}</Fragment>,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => {
                let className = '';
                let style = {};
                let title = '';

                if (status === 'completed') {
                    title = 'Hoàn thành';
                    className = 'label-light-success font-weight-bold';
                    style = { backgroundColor: '#4caf501a', color: '#4caf50', border: '1px solid #4caf501a' };
                }
                if (status === 'pending') {
                    title = 'Đang chờ';
                    className = 'label-light-warning font-weight-bold';
                    style = { backgroundColor: '#ff98001a', color: '#ff9800', border: '1px solid #ff98001a' };
                }
                if (status === 'failed') {
                    title = 'Thất bại';
                    className = 'label-light-danger font-weight-bold';
                    style = { backgroundColor: '#f443361a', color: '#f44336', border: '1px solid #f443361a' };
                }

                return (
                    <div className={className} style={style}>
                        {title}
                    </div>
                );
            },
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at: string) => <span>{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
    ];
    return (
        <Table
            columns={columns}
            dataSource={histories.map((history, index) => ({ key: index, ...history }))}
            pagination={false}
            scroll={{ y: 440, x: 'max-content' }}
        />
    );
}

export default Callbacks;
